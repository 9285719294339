/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import {
  resolveImage,
  resolveCB,
  resolveAboveCta,
  resolvePackage,
} from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query ReceiverQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "receivers", uri: $uri, siteId: $siteId) {
        title
        url
        ... on Craft_receivers_receiver_Entry {
          #Hero
          heroTitle
          entrySubtitle
          embedCode
          #Sections
          sections {
            ...SectionFragment
          }
          # Resources
          heading1
          subHeading1
          resourcesPdfList {
            ... on Craft_resourcesPdfList_pdfLink_BlockType {
              anchorLink {
                text
                url
              }
            }
          }
          resourcesVideoList {
            ... on Craft_resourcesVideoList_videoLink_BlockType {
              anchorLink {
                text
                url
              }
            }
          }
          resourcesArticlesList {
            ... on Craft_caseStudies_caseStudy_Entry {
              url
              title
              heroTitle
              articleCategory {
                ... on Craft_blog_Category {
                  title
                }
              }
            }
          }
          link5 {
            url
            text
          }
          # Dealership
          heading
          subHeading
          link1 {
            text
            url
          }
          # Pricing Calculator
          hideCalculator
          hideFinance
          defaultCalculatorTab
          copyrightText
          transmittersHeading
          displaysHeading
          pricingCalculatorHeading
          financeTabText
          buyTabText
          buyPriceHeading
          financeHeading
          financeSubheading
          financePlans {
            ... on Craft_financePlans_TableRow {
              months
              monthText
              summaryText
              interest
            }
          }
          financePriceHeading
          images(withTransforms: ["pricingCarousel"]) {
            width(transform: "pricingCarousel")
            height(transform: "pricingCarousel")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "pricingCarousel")
          }
          transmitters {
            ... on Craft_transmitters_transmitter_Entry {
              title
              uid
            }
            ... on Craft_transmitters_calcTransmitter_Entry {
              title
              uid
              metaDescription
            }
          }
          displays {
            ... on Craft_displays_displays_Entry {
              title
              uid
            }
            ... on Craft_displays_calcDisplay_Entry {
              title
              uid
              metaDescription
            }
          }
          includes {
            ... on Craft_includes_TableRow {
              info
            }
          }
          falconPackages {
            ... on Craft_falconPackages_package_BlockType {
              id
              uid
              options {
                ... on Craft_transmitters_transmitter_Entry {
                  typeHandle
                  title
                  uid
                }
                ... on Craft_transmitters_calcTransmitter_Entry {
                  typeHandle
                  title
                  uid
                  metaDescription
                }
                ... on Craft_displays_displays_Entry {
                  typeHandle
                  title
                  uid
                }
                ... on Craft_displays_calcDisplay_Entry {
                  typeHandle
                  title
                  uid
                  metaDescription
                }
              }
              packagePrice
              tradeInDiscount
              additionalIncludes {
                ... on Craft_additionalIncludes_TableRow {
                  info
                }
              }
            }
          }
          showTradeIn
          tradeInText
          ctaLink {
            text
            url
          }
          asteriskText
          #Summary
          openSummary
          productDescription
          summaryHeading
          summaryFinanceHeading
          summaryBuyHeading
          link2 {
            text
            url
          }
          link3 {
            text
            url
          }
          link4 {
            text
            url
          }
          linkAnchor {
            text
            url
          }
          receiverHeading
          transmitterHeading
          displayHeading
          accessoriesHeading
          contactUsText
          disclosureButtonText
          disclosureHeading
          disclosureCopy
          #Above Footer CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  if (!entry) return null;

  const {
    title,
    // Hero
    heroTitle,
    entrySubtitle,
    sections,
    embedCode,
    // Resources
    heading1,
    subHeading1,
    resourcesPdfList,
    resourcesVideoList,
    resourcesArticlesList,
    link5,
    // CTA
    heading,
    subHeading,
    link1,
    // Pricing
    hideCalculator,
    hideFinance,
    defaultCalculatorTab,
    pricingCalculatorHeading,
    images,
    includes,
    displaysHeading,
    displays,
    transmittersHeading,
    transmitters,
    falconPackages,
    ctaLink,
    showTradeIn,
    tradeInText,
    financeTabText,
    buyTabText,
    buyPriceHeading,
    financeHeading,
    financeSubheading,
    financePlans,
    financePriceHeading,
    copyrightText,
    asteriskText,
    /// Summary
    openSummary,
    productDescription,
    summaryHeading,
    summaryFinanceHeading,
    summaryBuyHeading,
    link2,
    link3,
    link4,
    linkAnchor,
    receiverHeading,
    transmitterHeading,
    displayHeading,
    accessoriesHeading,
    contactUsText,
    disclosureButtonText,
    disclosureHeading,
    disclosureCopy,
    // Abover Footer
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
    url,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
      embedCode,
      featured: !!embedCode?.length,
      headingBelow: !!embedCode?.length,
    },
    sections: resolveCB(sections),
    resources: {
      heading: heading1,
      subheading: subHeading1,
      pdfs: resourcesPdfList?.map(pdf => pdf?.anchorLink),
      videos: resourcesVideoList?.map(video => video?.anchorLink),
      articles: resourcesArticlesList?.map(article => {
        return {
          ...article,
          category: article?.articleCategory?.[0]?.title,
        };
      }),
      link: link5,
    },
    findADealer: {
      heading,
      summary: subHeading,
      link: link1,
    },
    pricing: {
      heading: pricingCalculatorHeading,
      images,
      displays,
      transmitters,
      transmittersHeading,
      displaysHeading,
      includes: includes?.map(item => item?.info),
      packages: resolvePackage(falconPackages),
      financeTabText,
      buyTabText,
      buyPriceHeading,
      financeHeading,
      financeSubheading,
      financePlans,
      financePriceHeading,
      link: ctaLink,
      showTradeIn,
      tradeInText,
      openSummary,
      summaryFinanceHeading,
      summaryBuyHeading,
      summary: {
        heading: summaryHeading,
        productDescription,
        receiverHeading,
        transmitterHeading,
        displayHeading,
        accessoriesHeading,
        link2,
        link3,
        link4,
        linkAnchor,
        contactUsText,
        disclosureButtonText,
        disclosureHeading,
        disclosureCopy,
      },
      copyrightText,
      hideCalculator,
      hideFinance,
      defaultCalculatorTab,
      asteriskText,
      url,
    },
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
